import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { RenderAst } from "../components/styledComponents"
import TopImage from "../components/topImage"

export default function Projects({ data }) {
  const { htmlAst, frontmatter, topImage } = data.markdownRemark
  return (
    <Layout>
      <Seo title={frontmatter.title} />
      <TopImage
        topImage={topImage.childImageSharp.gatsbyImageData}
        title={frontmatter.title}
      />
      <div className="container mx-auto flex flex-wrap justify-center w-9/12 sm:w-9/12 md:w-9/12 lg:w-4/6 xl:w-3/6 2xl:w-3/6 pt-10">
        {RenderAst(htmlAst)}
      </div>
      <div className="flex justify-center pt-10 pb-10">
        <form
          className="container mx-auto w-9/12 sm:w-9/12 md:w-9/12 lg:w-4/6 xl:w-3/6 2xl:w-3/6"
          name="Formular Voluntar V1"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="Formular Voluntar V1" />
          <p hidden>
            <label>
              Don't fill this out: <input name="bot-field" />
            </label>
          </p>
          <div className="relative z-0 w-full mb-6 group">
            <input
              type="text"
              name="nume"
              id="floating_name"
              className="block py-2.5 px-0 w-full text-lg bg-transparent border-1 border-b-2 border-gray-600 focus:border-blue-500 outline-none peer"
              placeholder=" "
              required
            />
            <label
              htmlFor="floating_name"
              className="peer-focus:font-medium absolute text-lg text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
               peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Nume
            </label>
          </div>
          <div className="relative z-0 w-full mb-6 group">
            <input
              type="tel"
              name="phone"
              id="floating_phone"
              className="block py-2.5 px-0 w-full text-lg bg-transparent border-1 border-b-2 border-gray-600 focus:border-blue-500 outline-none peer"
              placeholder=" "
            />
            <label
              htmlFor="floating_phone"
              className="peer-focus:font-medium absolute text-lg text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
               peer-focus:text-blue-600 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Telefon
            </label>
          </div>
          <div className="relative z-0 w-full mb-6 group">
            <input
              type="email"
              name="email"
              id="floating_email"
              className="block py-2.5 px-0 w-full text-lg bg-transparent border-1 border-b-2 border-gray-600 focus:border-blue-500 outline-none peer"
              placeholder=" "
              required
            />
            <label
              htmlFor="floating_email"
              className="peer-focus:font-medium absolute text-lg text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
               peer-focus:text-blue-600 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Email
            </label>
          </div>
          <div className="relative z-0 w-full mb-6 group">
            <input
              type="text"
              name="localitate"
              id="floating_location"
              className="block py-2.5 px-0 w-full text-lg bg-transparent border-1 border-b-2 border-gray-600 focus:border-blue-500 outline-none peer"
              placeholder=" "
              required
            />
            <label
              htmlFor="floating_location"
              className="peer-focus:font-medium absolute text-lg text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
               peer-focus:text-blue-600 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Localitate
            </label>
          </div>
          <div className="relative z-0 w-full mb-6 group">
            <input
              type="number"
              name="varsta"
              id="floating_age"
              className="block py-2.5 px-0 w-full text-lg bg-transparent border-1 border-b-2 border-gray-600 focus:border-blue-500 outline-none peer"
              placeholder=" "
              required
            />
            <label
              htmlFor="floating_age"
              className="peer-focus:font-medium absolute text-lg text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
               peer-focus:text-blue-600 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Varsta
            </label>
          </div>
          <div className="relative z-0 w-full mb-6 group pb-20 sm:pb-12 md:pb-6">
            <input
              type="text"
              name="ocupatie"
              id="floating_job"
              className="block py-2.5 px-0 w-full text-lg bg-transparent border-1 border-b-2 border-gray-600 focus:border-blue-500 outline-none peer"
              placeholder=" "
              required
            />
            <label
              htmlFor="floating_job"
              className="peer-focus:font-medium absolute text-lg text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
               peer-focus:text-blue-600 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
            >
              Ocupatie
            </label>
          </div>
          <div className="relative z-0 w-full mb-6 group pb-4">
            <textarea
              name="activity"
              id="floating_activity"
              className="block py-6 sm:py-4 md:py-2 lg:py-0 xl:py-0 2xl:py-0 px-0 w-full text-lg bg-transparent border-1 border-b-2 border-gray-600 focus:border-blue-500 outline-none peer"
              placeholder=" "
              rows={3}
              required
            />
            <label
              htmlFor="floating_activity"
              className="peer-focus:font-medium absolute text-lg text-gray-500 duration-300 transform 
              -translate-y-28 sm:-translate-y-16 md:-translate-y-16 lg:-translate-y-16  xl:-translate-y-12 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
              peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75
               peer-focus:-translate-y-28 sm:peer-focus:-translate-y-16 md:peer-focus:-translate-y-16 lg:peer-focus:-translate-y-16  xl:peer-focus:-translate-y-12"
            >
              Ați mai activat ca voluntar? Dacă răspunsul este DA, va rog să
              precizați perioada și tipul de activitate.
            </label>
          </div>
          <div className="relative z-0 w-full mb-6 group pb-4">
            <textarea
              name="info"
              id="floating_info"
              className="block py-4 sm:py-4 md:py-2 lg:py-0 xl:py-0 2xl:py-0 px-0 w-full text-lg bg-transparent border-1 border-b-2 border-gray-600 focus:border-blue-500 outline-none peer"
              placeholder=" "
              rows={3}
              required
            />
            <label
              htmlFor="floating_info"
              className="peer-focus:font-medium absolute text-lg text-gray-500 duration-300 transform -translate-y-10 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
               peer-focus:text-blue-600 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75
               peer-focus:-translate-y-10"
            >
              Alte informatii relevante.
            </label>
          </div>
          <div className="relative z-0 w-full mb-6 group">
            <textarea
              name="motivation"
              id="floating_motivation"
              className="block py-4 sm:py-4 md:py-2 lg:py-0 xl:py-0 2xl:py-0 px-0 w-full text-lg bg-transparent border-1 border-b-2 border-gray-600 focus:border-blue-500 outline-none peer"
              placeholder=" "
              rows={3}
              required
            />
            <label
              htmlFor="floating_motivation"
              className="peer-focus:font-medium absolute text-lg text-gray-500 duration-300 transform -translate-y-10 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0
               peer-focus:text-blue-600 peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75
               peer-focus:-translate-y-10"
            >
              Motivatia.
            </label>
          </div>
          <div className="relative z-0 w-full mb-6 group">
            <input type="checkbox" name="checkBox" id="checkBox" className="form-checkbox h-4 w-4 pt-3" required />
            <label
              htmlFor="checkBox"
              className="pl-3 text-lg text-gray-500"
            >
              Sunt de acord cu 
            </label>
            <Link to="/politicaDeConfidentialitate" className="hover:underline"> politica de confidentialitate.</Link>
          </div>

          <button
            type="submit"
            className="text-white bg-[#264248] hover:bg-[#4FA3A5] transition ease-in-out delay-150 hover:scale-110 duration-500 focus:ring-4 focus:outline-none focus:ring-blue-800 rounded-lg text-xl w-full sm:w-auto px-5 py-2.5 text-center"
          >
            Trimite
          </button>
        </form>
      </div>
    </Layout>
  )
}

export const voluntarQuery = graphql`
  query VoluntarPage {
    markdownRemark(fileAbsolutePath: { regex: "/voluntariat/" }) {
      htmlAst
      frontmatter {
        title
      }
      topImage {
        childImageSharp {
          gatsbyImageData(
            transformOptions: { cropFocus: SOUTHEAST, fit: CONTAIN }
            placeholder: BLURRED
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
`
